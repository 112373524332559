<template>
  <nav :class="{active: navActive}">
    <div class="inner">
      <ul class="gnb">
        <li>
          <router-link
            to="/consulting"
            @click.native="$emit('update:navActive', false)"
            class="anime1"
            :class="{active: $route.name === 'consulting'}"
          >
            Consulting
          </router-link>
        </li>
        <li>
          <router-link
            to="/works"
            @click.native="$emit('update:navActive', false)"
            class="anime1"
            :class="{active: $route.name === 'works-list' || $route.name === 'works-detail'}"
          >
            Projects
          </router-link>
        </li>
        <li>
          <router-link
            to="/magazine"
            @click.native="$emit('update:navActive', false)"
            class="anime1"
            :class="{
              active:
                $route.name === 'magazine-list' ||
                $route.name === 'magazine-detail' ||
                $route.name === 'magazine-item'
            }"
          >
            Magazine
          </router-link>
        </li>
        <li>
          <router-link
            to="/careers"
            @click.native="$emit('update:navActive', false)"
            class="anime1"
            :class="{active: $route.name === 'careers'}"
          >
            Careers
          </router-link>
        </li>
        <li>
          <router-link
            to="/request"
            @click.native="$emit('update:navActive', false)"
            class="anime1"
            :class="{active: $route.name === 'request'}"
          >
            Request
          </router-link>
        </li>
        <li>
          <router-link
            to="/about"
            @click.native="$emit('update:navActive', false)"
            class="anime1"
            :class="{active: $route.name === 'about'}"
          >
            About
          </router-link>
        </li>
      </ul>
      <ul class="sns">
        <!--<li>
          <router-link to="/" @click.native="$emit('update:navActive', false)" class="anime1">
            Home
          </router-link>
        </li>-->
        <li>
          <a
            href="https://www.behance.net/emotionglobal"
            target="_blank"
            title="새창열림"
            class="anime1"
          >
            Behance
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/emotion.co.kr/" target="_blank" class="anime1"
            >Facebook</a
          >
        </li>
        <!-- <li>
          <router-link
            to="/careers"
            @click.native="$emit('update:navActive', false)"
            class="anime1"
          >
            Career
          </router-link>
        </li>
        <li>
          <router-link to="/about" @click.native="$emit('update:navActive', false)" class="anime1">
            Introduce
          </router-link>
        </li> -->
      </ul>
      <div class="mark">
        <div class="mark-inner">
          <p class="en">ⓒ e · motion Global Inc. All rights reserved.</p>
          <!-- <p class="ko">위대함을 만들어 갑니다.</p> -->
        </div>
      </div>
    </div>
    <div class="bg"></div>
  </nav>
</template>
<script>
  export default {
    name: "AppNavigation",
    props: ["navActive"]
  };
</script>
<style lang="scss" scoped>
  nav {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: $globalWidth;
    height: 100%;
    overflow: auto;
    .bg {
      position: fixed;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ee2c3c;
    }
    .inner {
      z-index: 3;
      position: relative;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      min-height: 100vh;
      box-sizing: border-box;
      padding: 120px 38px 80px 20px;
    }
    .gnb {
      font-family: "Montserrat", sans-serif;
      letter-spacing: 0;
      li {
        font-size: 34px;
        line-height: 62px;
        font-weight: 500;
        color: #fff;
        overflow: hidden;
        margin-top: 10px;
        height: 0;
        // &:nth-child(n + 3) {
        //   margin-top: 5px;
        // }
      }
      a {
        font-family: "Montserrat", sans-serif;
        border-radius: 33px;
        padding: 0 16px;
        transform: translateX(-110%);
        display: inline-block;
        &.active {
          background: #fff;
          color: #ee2c3c;
        }
      }
    }
    .sns {
      // position: absolute;
      // top: 116px;
      // bottom: 30px;
      // right: 50px;
      margin-top: 28px;
      margin-right: -10px;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      display: flex;
      font-family: "Montserrat", sans-serif;
      letter-spacing: 0;
      justify-content: flex-end;
      /* li:first-child {
        margin-right: auto;
      }*/
      li {
        position: relative;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        line-height: 14px;
        color: #fff;
        font-weight: 500;
        overflow: hidden;
      }
      a {
        display: block;
        padding: 10px;
        transform: translateX(-110%);
        /*&:before {
        content: '';
        position: absolute;
        bottom: 10px;
        left: 10px;
        display: block;
        width: calc(100% - 20px);
        height: 1px;
        background: #fff;
      }*/
      }
    }
    .mark {
      position: absolute;
      bottom: 41px;
      right: 35px;
      left: 35px;
      font-size: 10px;
      line-height: 10px;
      // .mark-inner {
      //   opacity: 0;
      //   &:before {
      //     content: "";
      //     display: block;
      //     width: 70px;
      //     height: 71px;
      //     margin: 0 auto 30px;
      //     background: url(../assets/images/gnb-mark.png) no-repeat center / cover;
      //   }
      // }
      .en {
        @include montserrat;
        letter-spacing: 0;
        color: #fff;
        text-align: center;
      }
      .ko {
        margin-top: 7px;
        color: #fb8992;
      }
    }

    &.active {
      .gnb li {
        height: 62px;
      }
    }
  }
</style>
